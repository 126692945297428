import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class About extends Component {
  render() {
    return (
        <section className="about-section pt-115 pb-115">
        <div className="container">
          <div className="down-arrow-wrap text-center">
            <Link to="#" className="down-arrow"><i className="fal fa-arrow-down" /></Link>
          </div>
          <div className="section-title about-title text-center mb-20">
          <h1 style={{fontWeight: 'bold', paddingBottom: '5%'}}>Boat Rental in Vela Luka - Antonio Rent</h1>
            <h3>
              Welcome to Antonio Rent, the best boat rental service in Vela Luka, Korčula. We offer a wide range of boats for rent,
              suitable for all types of adventures and experiences.
            </h3>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
