import React, { useState  } from 'react'
import Headerfour from '../layouts/Headerfour';
import Footerthree from '../layouts/Footerthree';
import About from '../sections/homepage-one/About';
import Mainbanner from '../sections/homepage-two/Banner';
import Textblock from '../sections/homepage-two/Textblock';
import Textblock1 from '../sections/homepage-two/Textblock1';
import FloatingWhatsApp from 'react-floating-whatsapp';
import Testimonial from '../sections/homepage-one/Testimonials';
import logo from '../../assets/img/white_logo.png'

const Hometwo = () => {
  const [message, setMessage] = useState('');
  const phoneNumber = '+385996964190';

  const handleInput = (e) => {
    setMessage(e.target.value);
  };

  const handleClick = () => {
    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div>
      <Headerfour />
      <Mainbanner />
      <About />
      <Textblock />
      <Textblock1 />
      <Testimonial />
      <Footerthree />
      <FloatingWhatsApp
        onInput={handleInput}
        phoneNumber={phoneNumber}
        text={message}
        className='whatsapp'
        position="bottom-right"
        accountName="Antonio"
        onClick={handleClick}
        avatar={logo}
        darkMode={true}
        chatMessage='WhatsApp us for expert advice and exclusive offers! 🚤🤝'
        styles={{zIndex: 99, width: 70, height: 70}}
      />
    </div>
  );
};

export default Hometwo;