import React, { Component } from 'react'
import Header from '../layouts/Headerfour';
import Footerthree from '../layouts/Footerthree';
import { Link } from 'react-router-dom';

import arkos from '../../assets/img/arkos1.jpg';
import saver from '../../assets/img/08.jpg';
import passara from '../../assets/img/pasara4601.jpg';
import vis from '../../assets/img/vis.jpg';
import hvar from '../../assets/img/hvar.jpg';
import lastovo from '../../assets/img/lstovo.jpg';
import korčula from '../../assets/img/korčula.jpg';



class Roomlist extends Component {

   roomlistPosts = [
    [
      {
        photo: arkos,
        title: 'Arkos 647 sundeck',
        url: '/details/boats/0',
        btn: 'More details',
        desc: 'Arkos is a highly capable watercraft, boasting exceptional maritime capabilities. Measuring 6.5 meters in length and 2.2 meters in width, it is equipped with a powerful 150hp engine. Able to accommodate between 8 and 9 passengers, Arkos can reach a cruising speed of 25 mph, making it a reliable and exciting option for any nautical adventure.',
    },
    {
        photo: saver,
        title: 'Saver 550 open ',
        url: '/details/1',
        btn: 'More details',
        desc: 'The Saver 550 Open is an ideal watercraft for a group of up to 6 individuals. With its length of 5.5 meters and width of 1.7 meters, the boat offers ample space on the bow and aft sundecks for cruising and soaking up the sun. Additionally, its 115 hp engine provides efficient and reliable power for an optimal boating experience.',
    },
    {
      photo: passara,
      title: 'Passara',
      url: '/details/3',
      btn: 'More details',
      desc: 'Pasara is a charming traditional Dalmatian vessel designed for enjoyable short excursions on the sea. With its 5hp engine, it is easy to operate and can be driven by anyone, including those without a license.',
    },
   ],[
      {
        photo: vis,
        title: 'Vis & Modra špilja(Blue Cave)',
        url: '/details/4',
        btn: 'More details',
        desc: 'Explore the stunning island of Vis with one of our most popular excursions. This unforgettable trip takes you to visit the natural wonder of the Blue Cave, picturesque Komiža town, and the breathtaking Stiniva bay. There is even more to discover along the way!',
    },
    {
        photo: lastovo,
        title: 'Lastovo & Lastovsko otočje',
        url: '/details/5',
        btn: 'More details',
        desc: 'Experience the breathtaking beauty of Lastovo Island, a paradise for nature and outdoor enthusiasts. Discover the hidden gems of its numerous secluded bays and indulge in swimming, sunbathing, snorkeling, and observing the vibrant underwater world and nature.',
    },
    {
      photo: hvar,
      title: 'Hvar & Pakleni otoci',
      url: '/details/6',
      btn: 'More details',
      desc: 'Explore the stunning island of Hvar, known as one of the most beautiful islands on the Dalmatian coast and boasting the highest number of sunny hours in Croatia. Our trip takes you to Palmižana, Pakleni Islands, and the charming old town of Hvar. Experience the natural beauty, rich history, and vibrant culture of this Mediterranean paradise.',
  },
  {
    photo: korčula,
    title: 'Korčula',
    url: '/details/7',
    btn: 'More details',
    desc: 'Embarking on a boat trip around the enchanting island of Korcula is an ideal way to discover the hidden gems and breathtaking sights that our island has to offer. With its numerous bays and islets, Korcula boasts an abundance of unspoiled natural beauty waiting to be explored.',
}
    ]
  ];
  

  constructor(props){
    super(props) 
  
    this.state = {
      data: [],
      index: 0,
      title: "",
      nav1: null,
    }


  }

  getData() {
    let lastQueryParam = window.location.href.split("/").pop();
    if(lastQueryParam === "boats") {
      this.setState({
           index:0,
           title: "Boat Rental"
         })
    }else {
      this.setState({
        index:1,
        title: "Boat Trips"
      })
    }
   
     
  }

  componentDidMount() {
    this.getData()
  }

  componentDidUpdate(prevProps,prevState) {
    const locationChanged =  this.props.location ;
    if(locationChanged !== prevProps.location) this.getData()
  // INCORRECT, will *always* be false because history is mutable.
  
  }
  render() {
    return (
      <div>
        <Header/>
        {/*====== BREADCRUMB PART START ======*/}
        <section  className="breadcrumb-area" style={{backgroundImage: 'url(assets/img/bg/04.jpg)'}}>
          <div className="container">
            <div className="breadcrumb-text">
              <span>Antonio rent - vela luka</span>
              <h2 className="page-title">{this.state.title}</h2>
              <ul className="breadcrumb-nav">
                <li><Link to="/">Home</Link></li>
                <li className="active">{this.state.title}</li>
              </ul>
            </div>
          </div>
        </section>
        {/*====== BREADCRUMB PART END ======*/}
        {/*====== ROOM-LIST START ======*/}
        <section className="room-section room-list-style pt-120 pb-120">
          <div className="container">
            <div className="room-list-loop">
            {this.roomlistPosts[this.state.index].map((item, i) => (
              <div key={i} className="room-box mb-30">
                <div className="row no-gutters justify-content-center">
                  <div className="col-lg-5 col-md-10">
                    <div className="room-img-wrap">
                      <div className="room-img" style={{backgroundImage: "url("+ item.photo + ")"}} />
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-10">
                    <div className="room-desc">
                      <div className="row align-items-center">
                        <div className="col-sm-8">
                          <ul className="icons">
                              <li><i className="fal fa-anchor" /> </li>
                              <li><i className="fal fa-grip-lines-vertical" /></li>
                              <li><i className="fal fa-swimming-pool" /></li>
                              <li><i className="fal fa-umbrella-beach" /></li>
                              <li><i className="fal fa-sun" /></li>
                              <li><i className="fal fa-male" /></li>
                          </ul>
                          <h4 className="title"><Link to={item.url}>{item.title}</Link></h4>
                          <p>
                          {item.desc}
                          </p>
                        </div>
                        <div className="col-sm-4">
                          <div className="text-sm-center">
                            <Link to={item.url} className="book-btn">{item.btn}</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            </div>
          </div>
        </section>
        {/*====== ROOM-LIST END ======*/}
        <Footerthree />
      </div>

    );
  }
}

export default Roomlist;
